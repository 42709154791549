<template>
  <!-- <div>
    <div class="navbar-area">
      <div class="mobile-nav">
        <router-link
          to="/"
          class="logo"
        >
          <img
            src="@/assets/images/logomameri.png"
            alt="logo"
          >
        </router-link>
      </div>
      <div class="main-nav">
        <div class="container">
          <nav
            class="navbar navbar-expand-lg navbar-light"
            style="margin-top:-20px;"
          >
            <router-link
              class="navbar-brand"
              to="/"
            >
              <img
                src="@/assets/images/logomameri.png"
                alt="logo"
                width=""
                height="70px"
              >
            </router-link>
            <div
              id="navbarSupportedContent"
              class="collapse navbar-collapse mean-menu"
            >
              <ul
                class="navbar-nav ml-auto justify-items-center"
                style="font-size: 15px;"
              >
                <li class="nav-item">
                  <router-link
                    to="/"
                    class="nav-link"
                  >
                    Accueil
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/affreteurs"
                    class="nav-link"
                  >
                    Affreteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/transporteurs"
                    class="nav-link"
                  >
                    Transporteur
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    to="/documentheque"
                    class="nav-link"
                  >
                    Documenthèque
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link
                    to="/contact"
                    class="nav-link"
                  >
                    Contact
                  </router-link>
                </li>
                <li
                  v-if="!status"
                  class="nav-item ml-1"
                >
                  <router-link
                    to="/register"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      size="16"
                      class="align-middle text-body"
                    /> S'inscrire
                  </router-link>
                </li>
                <li
                  v-if="!status"
                  class="nav-item"
                >
                  <router-link
                    to="/login"
                    class="nav-link"
                  >
                    <feather-icon
                      icon="LogInIcon"
                      size="16"
                      class="align-middle text-body"
                    /> Se Connecter
                  </router-link>
                </li>
                <li
                  v-if="status"
                  class="nav-item"
                >
                  <router-link
                    class="nav-link"
                    @click="redirect({ role: role })"
                  >
                    Mon espace
                  </router-link>
                </li>
                <li>
                  <router-link
                    v-if="!status"
                    class="btn btn-primary p-1 mx-1"
                    to="/expert-register"
                  >
                    Devenir expert
                  </router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div> -->
  <!-- ======= Header ======= -->
  <header
    id="header"
    class="header fixed-top navbar-area"
  >
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <router-link
        to="index.html"
        class="logo d-flex align-items-center"
      >
        <img
          src="assets/img/logo.png"
          alt=""
        >
        <span>E-CEV</span>
      </router-link>

      <nav
        id="navbar"
        class="navbar"
      >
        <ul>
          <li><router-link
            class="nav-link scrollto active"
            to="/"
          >Accueil</router-link></li>
          <li><router-link
            class="nav-link scrollto"
            to="/register"
          >A propos</router-link></li>
          <li><router-link
            class="nav-link scrollto"
            to="/register"
          >Services</router-link></li>
          <li><router-link
            class="nav-link scrollto"
            to="/register"
          >Contact</router-link></li>
          <li><router-link
            v-if="!status"
            class="getstarted scrollto"
            to="/register"
          >Se lancer</router-link>
          </li>
          <li><router-link
            v-if="status"
            class="getstarted scrollto"
            to="/login"
          >Mon espace</router-link>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle" />
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  onMounted,
} from '@vue/composition-api'
import store from '@/store'
import useAuthUser from '@/services/authentification/userService'
// import {
//   BNavbarNav, BNavItemDropdown, BNavItem, BDropdownItem, BCollapse, BNavbarToggle, BNavbarBrand, BNavbar,
// } from 'bootstrap-vue'

export default {
  components: {
    // BNavbarNav,
    // BNavItem,
    // BNavItemDropdown,
    // BDropdownItem,
    // BCollapse,
    // BNavbarToggle,
    // BNavbarBrand,
    // BNavbar,
    // Navbar Components
  },
  data() {
    return {
      role: '',
      status: false,
    }
  },
  mounted() {
    this.status = store.state.authStore.authenticated
    // eslint-disable-next-line no-unused-expressions
    JSON.parse(store.state.authStore.user) ? this.role = JSON.parse(store.state.authStore.user).role : this.role = ''
  },
  methods: {
    getStatus() {
      this.status = this.mystore
    },
    select(lang) {
      this.$i18n.locale = lang
    },
  },
  setup() {
    const {
      getAuthUser, getUserRole, redirectUser,
    } = useAuthUser()
    const mystore = false
    const role = ''
    onMounted(
      async () => {
      },
    )
    const redirect = data => {
      redirectUser(data)
    }
    return {
      getAuthUser,
      getUserRole,
      redirect,
      mystore,
      role,
      redirectUser,
    }
  },
}
</script>
<style>
.nav-link{
  font-weight: 600;
}
.nav-link:hover{
  font-weight: 600;
  color: #FF5E13 !important;
}
.navbar-light .navbar-toggler {
    border-color: white !important;
    color: black !important;
}
.is-sticky{
  background: white;
}
</style>
